import React from "react"
import { Link,graphql } from 'gatsby'

import Layout from "../../components/layout"
import Next from "../../components/next"

const Page = ({data}) => (
  <Layout title="Smartix" keywords={[`rail api`, `train ticket`, `api`]} submenu={data.allRailapisubmenuJson.edges}>
    <content>
      <h1>User Interface</h1>

      <p><Link to="/smartix/" className="printLink">smartix</Link> does not provide any form of user interface.</p>

      <p>We feel that you are unlikely to simply want a clone of someone elses' retail site. You probably want to integrate it into 
      an existing travel retail site.</p>

      <p>That said, we can provide a great deal of assistance and advice that can 
      significantly shorten the time it takes to add rail to your exiting retail application.</p>

      <p>Your chosen <Link to="/smartix/journey-planner/">journey planner supplier</Link> will similarly be able to provide 
      assistance on how to best layout their search results for presentation to your customers.</p>

      <Next submenu={data.allRailapisubmenuJson.edges}/>

    </content>
  </Layout>
)

export const query = graphql`
  query {
    allRailapisubmenuJson {
      edges {
        node {
          title
          path
        }
      }
    }
  } 
`

export default Page